import { Checkbox, Form, Input, Row, Switch, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ActivityApi from '^/api-services/activities/activities.service';
import axios from '^/api-services/axiosInstance';
import { ICreateCategoryRequest } from '^/api-services/activities/types';
import useAPI from '^/api-services/useApi';

import { ModalPopup } from '^/app/components';
import { CATEGORY_LABELS } from '^/common/labels-english';
import { toSentenceCase } from '^/common/utils/text-utils';
import { Pagination } from '^/api-services/activities/types';
import { ActivitiesNames } from '^/common/activities-constants';
const { Option } = Select;
type Props = {
  id: string;
  isTeacher?: boolean;
  onClose: (refresh?: boolean) => void;
};

export const CategoryModal: React.FC<Props> = (props: Props) => {
  const { id, isTeacher, onClose } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const labels = CATEGORY_LABELS.modal;
  const [form] = Form.useForm();
  const validator = [
    {
      required: true,
      max: 255,
    },
  ];
  const { callAPI: createSchool, ...createSchoolResponse } = useAPI(
    ActivityApi.createCategory
  );
  const { callAPI: updateSchool, ...updateSchoolResponse } = useAPI(
    ActivityApi.updateCategory
  );
  const { callAPI: fetchSchool, ...schoolResponse } = useAPI(
    ActivityApi.getCategoryDetails
  );
  // const { callAPI: fetchRules, ...rulesResponse } = useAPI<
  //   void,
  //   Pagination<IMessageRules>
  // >(ActivityApi.getMessageRules);

  // useEffect(() => {
  //   fetchRules();
  // }, []);

  // useEffect(() => {
  //   if (rulesResponse.response && !id) {
  //     // for create school, enable all the rules by default
  //     form.setFieldsValue({
  //       ['automated_message_rules_opted_in']: rulesResponse.response.results.map(
  //         (rule) => rule.id
  //       ),
  //     });
  //   }
  // }, [rulesResponse.response]);

  useEffect(() => {
    if (id) {
      // edit school. Fetch school details
      fetchSchool(id);
    }
  }, [id]);

  const onFormSubmit = (values: ICreateCategoryRequest) => {
    if (id) {
      updateSchool({ id: id, ...values });
    } else {
      createSchool(values);
    }
  };

  const onSuccess = (message: string) => {
    toast.success(message);
    setIsModalOpen(false);
    onClose(true);
  };

  const onError = ({ error }: { error: axios.AxiosError }) => {
    const data = error?.response?.data;
    const errorKey = data ? Object.keys(data)[0] : '';
    if (errorKey && Array.isArray(data[errorKey]) && data[errorKey].length) {
      data[errorKey][0] && toast.error(toSentenceCase(data[errorKey][0]));
    }
  };

  useEffect(() => {
    if (createSchoolResponse.response) {
      onSuccess(labels.onCreateSuccessMsg);
    } else if (createSchoolResponse.error) {
      onError(
        (createSchoolResponse.error as unknown) as { error: axios.AxiosError }
      );
    }
  }, [createSchoolResponse]);

  useEffect(() => {
    if (updateSchoolResponse.response) {
      onSuccess(labels.onUpdateSuccessMsg);
    } else if (updateSchoolResponse.error) {
      onError(
        (updateSchoolResponse.error as unknown) as { error: axios.AxiosError }
      );
    }
  }, [updateSchoolResponse]);

  return (
    <ModalPopup
      title={labels.title(id)}
      visible={isModalOpen}
      width="50%"
      onCancel={() => {
        setIsModalOpen(false);
        onClose(false);
      }}
      okText={labels.submitBtn}
      onOk={() => form.submit()}
      confirmLoading={
        createSchoolResponse.loading || updateSchoolResponse.loading
      }
    >
      {!schoolResponse.loading && (
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          colon={false}
          labelAlign="left"
          validateMessages={{ required: labels.required }}
          onFinish={onFormSubmit}
          initialValues={schoolResponse?.response}
          preserve={false}
        >
          <>
            <Form.Item
              name="category"
              label={labels.category}
              rules={validator}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="activities"
              label={labels.activities}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Please select an option"
                options={ActivitiesNames.map((messagePreset) => ({
                  value: messagePreset.type,
                  label: messagePreset.label,
                }))}
              />
            </Form.Item>
          </>
        </Form>
      )}
    </ModalPopup>
  );
};
