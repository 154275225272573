import { NavItem } from '@dabapps/roe';
import * as React from 'react';
import { IActivityPageUserDetail } from '^/api-services/users/types';
import { Button as AntdButton } from 'antd';
import { ACTIVITY_PAGE_TAB_COLOR } from '^/common/colors';

interface TabLinkProps {
  active: boolean;
  display: string;
  count?: number;
  onClick: () => void;
}

interface TabMenuProps {
  route: TABLE_TYPES;
  userDetail: IActivityPageUserDetail;
  onRouteChange: (val: TABLE_TYPES) => void;
}

function TabLink({ active, display, count, onClick }: TabLinkProps) {
  const labelStyle = {
    color: ACTIVITY_PAGE_TAB_COLOR,
    display: 'inline-block',
    padding: '0.5em',
    fontSize: '1em',
    background: 'none',
  };
  return (
    <NavItem active={active} className="margin-right-none">
      <AntdButton type="link" onClick={onClick} style={labelStyle}>
        <span className={active ? 'font-weight-bold' : ''}>{display}</span>
        <span className="font-weight-light margin-left-small">{count}</span>
      </AntdButton>
    </NavItem>
  );
}

export default function StudentDetailTabMenu({
  route,
  userDetail,
  onRouteChange,
}: TabMenuProps): JSX.Element {
  return (
    <ul className="nav content-tab-menu">
      <TabLink
        active={route === TABLE_TYPES.PENDING}
        display="Pending"
        count={userDetail.pending_activities_count}
        onClick={() => onRouteChange(TABLE_TYPES.PENDING)}
      />
      <TabLink
        active={route === TABLE_TYPES.ACTIVITIES}
        display="Activities"
        count={userDetail.number_of_activities}
        onClick={() => onRouteChange(TABLE_TYPES.ACTIVITIES)}
      />
      <TabLink
        active={route === TABLE_TYPES.MESSAGES}
        display="Messages"
        count={userDetail.number_of_messages}
        onClick={() => onRouteChange(TABLE_TYPES.MESSAGES)}
      />
      <TabLink
        active={route === TABLE_TYPES.PROFILE}
        display="miSurvey"
        onClick={() => onRouteChange(TABLE_TYPES.PROFILE)}
      />
      <TabLink
        active={route === TABLE_TYPES.ASSESSMENT}
        display="Assessments"
        count={userDetail.number_of_assessments}
        onClick={() => onRouteChange(TABLE_TYPES.ASSESSMENT)}
      />
    </ul>
  );
}

export enum TABLE_TYPES {
  PENDING = 'pending',
  ACTIVITIES = 'activities',
  ASSESSMENT = 'assessment',
  MESSAGES = 'messages',
  PROFILE = 'profile',
}
