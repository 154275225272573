import { hasFailed, hasSucceeded, isPending } from '@dabapps/redux-requests';
import { StoreState } from '^/store/types';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import ActivityPage from '^/app/authenticated/activities/activity-page/';
import ActivityOverview from '^/app/authenticated/activities/overview';
import addClass from '^/app/authenticated/classes/class-management/add-class';
import EditClass from '^/app/authenticated/classes/class-management/edit-class';
import Classes from '^/app/authenticated/classes/list';
import ImportUsers from '^/app/authenticated/users/import-users';
import Users from '^/app/authenticated/users/list';
import AddUser from '^/app/authenticated/users/user-management/add-user/';
import ChangePassword from '^/app/authenticated/users/user-management/change-password';
import EditUser from '^/app/authenticated/users/user-management/edit-user';
import {
  GET_FIRST_AVAILABLE_SCHOOL,
  LOGOUT,
} from '^/common/authentication/actions';
import { LoggedInUser } from '^/common/authentication/types';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import Nav from '^/common/nav';
import NotFoundPage from '^/common/not-found-page';
import PrivacyPolicy from '^/common/privacy-policy';
import { CurrentSchool } from '^/common/schools/reducers';
import TermsAndConditions from '^/common/terms-and-conditions';
import {
  loggedInUserIsAdmin,
  loggedInUserIsClusterAdmin,
  loggedInUserIsClusterManager,
} from '^/common/utils/roles';
import ActivityDashboard from '../pages/activity-dashboard';
import GlobalDashboard from '../pages/global-dashboard';
import { RouteConstants } from '^/common/route-constants';
import RecurringActivities from '../pages/recurring-activities';
import { ClusterMainPage } from '../pages/clusterManagement';
import { ClusterTreePage } from '../pages/clusterManagement/clusterTree';
import SchoolList from '../pages/schools/schoolList';
import CategoryList from '../pages/category/categoryList';
import SchoolSettings from '../pages/school-settings';
import { GraphDetails } from '../pages/activity-dashboard/graphs/graph-details';

interface StateProps {
  isLoading: boolean;
  hasLoaded: boolean;
  isLoggingOut: boolean;
  hasFailedToLoad: boolean;
  currentSchool: CurrentSchool;
  loggedInUser: LoggedInUser | null;
}

interface DispatchProps {
  push: typeof push;
}

type Props = RouteComponentProps & StateProps & DispatchProps;

export class Authenticated extends React.PureComponent<Props> {
  public render() {
    const {
      isLoading,
      currentSchool,
      hasFailedToLoad,
      hasLoaded,
      loggedInUser,
      isLoggingOut,
    } = this.props;

    if (
      !loggedInUserIsClusterAdmin(loggedInUser) &&
      !loggedInUserIsClusterManager(loggedInUser) &&
      !loggedInUserIsAdmin(loggedInUser) &&
      !currentSchool &&
      !isLoading &&
      !hasFailedToLoad &&
      !hasLoaded
    ) {
      return null;
    }

    if (hasFailedToLoad) {
      return <ErrorMessage />;
    }

    if (isLoading || isLoggingOut) {
      return (
        <>
          <Nav pathname={this.props.location.pathname} />
          <div className="padding-vertical-large">
            <LoadingSpinner />
          </div>
        </>
      );
    }
    // TODO: Need to rewrite while implementing the RBAC
    const allowedClusterAdminRoutes = () => {
      return (
        <Switch>
          <Route exact path="/" component={ActivityDashboard} />
          <Route
            exact
            path={RouteConstants.dashboard.route}
            component={ActivityDashboard}
          />
          <Route
            exact
            path="/users/edit-user/:userId/change-password"
            component={ChangePassword}
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route component={NotFoundPage} />
        </Switch>
      );
    };

    return (
      <>
        <Nav pathname={this.props.location.pathname} />
        {loggedInUserIsClusterAdmin(loggedInUser) ? (
          allowedClusterAdminRoutes()
        ) : currentSchool ? (
          <Switch>
            <Route exact path="/" component={ActivityDashboard} />
            <Route
              exact
              path={RouteConstants.dashboard.route}
              component={ActivityDashboard}
            />
            <Route
              exact
              path={RouteConstants.overview.route}
              component={ActivityOverview}
            />
            <Route path="/activities/:studentId" component={ActivityPage} />

            <Route exact path="/classes" component={Classes} />
            <Route exact path="/classes/add-class" component={addClass} />
            <Route
              exact
              path="/classes/edit-class/:classId"
              component={EditClass}
            />
            <Route exact path="/users" component={Users} />
            <Route exact path="/users/add-user" component={AddUser} />
            <Route exact path="/users/edit-user/:userId" component={EditUser} />
            <Route
              exact
              path="/users/edit-user/:userId/change-password"
              component={ChangePassword}
            />
            <Route exact path="/users/import-users" component={ImportUsers} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route
              exact
              path={RouteConstants.recurringActivities.route}
              component={RecurringActivities}
            />
            <Route
              exact
              path={RouteConstants.schoolSettings.route}
              component={SchoolSettings}
            />
            <Route exact path={'/graph-details'} component={GraphDetails} />
            {this.permissionRequiredRoutes()}
            <Route component={NotFoundPage} />
          </Switch>
        ) : (
          this.permissionRequiredRoutes()
        )}
      </>
    );
  }

  private additionalClusterManagerRoutes = () => {
    return (
      <Route
        exact
        path="/users/edit-user/:userId/change-password"
        component={ChangePassword}
      />
    );
  };

  private permissionRequiredRoutes = () => {
    if (loggedInUserIsClusterManager(this.props.loggedInUser)) {
      return this.additionalClusterManagerRoutes();
    }

    if (!loggedInUserIsAdmin(this.props.loggedInUser)) {
      return null;
    }
    return (
      <Switch>
        {!this.props.currentSchool && (
          <Route
            exact
            path="/users/edit-user/:userId/change-password"
            component={ChangePassword}
          />
        )}
        <Route
          exact
          path={RouteConstants.schools.route}
          component={SchoolList}
        />
        <Route
          exact
          path={RouteConstants.category.route}
          component={CategoryList}
        />
        <Route
          exact
          path={RouteConstants.global.route}
          component={GlobalDashboard}
        />
        <Route
          exact
          path={RouteConstants.cluster.route}
          component={ClusterMainPage}
        />
        <Route
          exact
          path={RouteConstants.cluster.route + '/:clusterId'}
          component={ClusterTreePage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  };
}

function mapStateToProps({
  responses,
  currentSchool,
  loggedInUser,
}: StoreState) {
  return {
    isLoading: isPending(responses, GET_FIRST_AVAILABLE_SCHOOL),
    hasFailedToLoad: hasFailed(responses, GET_FIRST_AVAILABLE_SCHOOL),
    hasLoaded: hasSucceeded(responses, GET_FIRST_AVAILABLE_SCHOOL),
    isLoggingOut: isPending(responses, LOGOUT),
    currentSchool,
    loggedInUser,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    push,
  })(Authenticated)
);
