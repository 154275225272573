import ApiService from '../apiService';
import { ServiceType } from '../serviceTypes';
import { formatPeriod } from '^/common/utils/stats-periods';
import {
  getActivityApiRequest,
  getActivityApiResponse,
} from '^/app/authenticated/activities/overview/stats/types';
import {
  IOldestActivityLoggedRequest,
  IOldestActivityLoggedResponse,
  IUserActivitySummary,
  IStudentActivitiesRequest,
  IStudentPendingActivityRequest,
  IAddActivityRequest,
  Pagination,
  IActivityListItem,
  IRecurringActivity,
  IRecurringActivityRequest,
  IStudentSummaryRequest,
  ICategoryListRequest,
  ICategoryListResponse,
  ICreateCategoryRequest,
  IUpdateCategoryRequest,
} from './types';
import { ISO_FORMAT } from '^/common/constants';
import moment from 'antd/node_modules/moment';

class ActivityApiClass {
  public getActivityHighlightStatsApi = ({
    school,
    year,
    month,
  }: getActivityApiRequest):
    | Promise<getActivityApiResponse>
    | getActivityApiResponse => {
    const period = formatPeriod(year, month);
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<getActivityApiResponse>({
      route: ['school-summary', period],
      query: {
        school,
      },
    });
  };

  public getOldestActivityLoggedDate = ({
    school,
  }: IOldestActivityLoggedRequest):
    | Promise<IOldestActivityLoggedResponse>
    | IOldestActivityLoggedResponse => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<IOldestActivityLoggedResponse>({
      route: ['school-oldest-logged-activity'],
      query: {
        school,
      },
    });
  };

  public getStudentSummary = ({ studentId, data }: IStudentSummaryRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<IUserActivitySummary>({
      route: ['student-summary', studentId],
      query: data,
    });
  };

  public getStudentActivities = ({
    student,
    page,
    page_size,
  }: IStudentActivitiesRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<Pagination<IActivityListItem>>({
      route: [],
      query: {
        student,
        page,
        page_size,
      },
    });
  };

  public getStudentPendingActivities = ({
    studentID,
    page,
    page_size,
  }: IStudentPendingActivityRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<Pagination<IActivityListItem>>({
      route: ['outstanding-activities', studentID],
      query: {
        page,
        page_size,
      },
    });
  };

  public addActivity = (data: IAddActivityRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.post<IAddActivityRequest>(
      {
        route: ['bulk-activity'],
      },
      {
        ...data,
        recurring_dates: data.recurring_dates.map((date) =>
          moment(date).format(ISO_FORMAT)
        ),
      }
    );
  };

  public getRecurringActivities = (data: IRecurringActivityRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<Pagination<IRecurringActivity>>({
      route: ['bulk-activity'],
      query: {
        ...data,
      },
    });
  };

  public deleteRecurringActivity = (id: string) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.delete({
      route: ['bulk-activity', id],
    });
  };
  public fetchCategoryPageData = (data: ICategoryListRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<ICategoryListResponse>({
      route: ['categories'],
      query: {
        ...data,
      },
    });
  };
  public getCategoryDetails = (id: string) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.get<ICreateCategoryRequest>({ route: ['categories', id] });
  };

  public createCategory = (data: ICreateCategoryRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    return service.post(
      {
        route: ['categories'],
      },
      data
    );
  };

  public updateCategory = (data: IUpdateCategoryRequest) => {
    const service: ApiService = new ApiService(ServiceType.ACTIVITY);
    const { id, ...updateValues } = data;
    console.log(data);
    return service.put(
      {
        route: ['categories', id],
      },
      updateValues
    );
  };
}

const ActivityApi = new ActivityApiClass();

export default ActivityApi;
