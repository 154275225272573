import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Modal,
  AutoComplete,
  Button,
  Input,
  Typography,
  Avatar,
  Spin,
} from 'antd';
import {
  RobotOutlined,
  CloseOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  ICopilotChatHistoryReponse,
  ICopilotChatHistoryRequest,
  ICopilotPrompt,
  IPresetPromptResponse,
} from '^/api-services/dashboard/types';
import useAPI from '^/api-services/useApi';
import DashboardApi from '^/api-services/dashboard/dashboard.service';
import { CLICK_DEBOUNCE_TIME, PAGE_SIZE } from '^/common/constants';
import { debounce } from 'underscore';
import { Pagination } from '^/api-services/activities/types';
const { Text } = Typography;

interface ChatMessage {
  type: 'user' | 'system';
  content: string;
  timestamp: Date;
  typewriter?: boolean;
}

interface TypewriterProps {
  text: string;
  onComplete?: () => void;
}

// Typewriter effect component
const Typewriter: React.FC<TypewriterProps> = ({ text, onComplete }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, 50);
      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, text, onComplete]);

  return <div style={{ whiteSpace: 'pre-line' }}>{displayText}</div>;
};

interface CopilotProps {
  presetPrompts?: IPresetPromptResponse[];
}

const CoPilot: React.FC<CopilotProps> = (props: CopilotProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  // const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [pageInfo, setPageInfo] = useState<{
    page: number;
    pageSize: number;
    next: boolean;
  }>({
    page: 1,
    pageSize: PAGE_SIZE,
    next: false,
  });

  const { callAPI: history, ...historyReponse } = useAPI<
    ICopilotChatHistoryRequest,
    Pagination<ICopilotChatHistoryReponse>
  >(DashboardApi.copilotChatHistory);

  const { presetPrompts } = props;
  const { callAPI: sendMessage, ...messageResponse } = useAPI<
    ICopilotPrompt,
    string
  >(DashboardApi.copilotSendMessage);

  const debouncedSendMessage = useCallback(
    debounce(sendMessage, CLICK_DEBOUNCE_TIME),
    []
  );
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  console.log('>>>>>>>>>>>>chat history', chatHistory);

  useEffect(() => {
    if (messageResponse.response) {
      const systemMessage: ChatMessage = {
        type: 'system',
        content: messageResponse.response,
        timestamp: new Date(),
        typewriter: true,
      };
      // Check if the new message is different from the last message in chatHistory
      setChatHistory((prev) => {
        if (
          prev.length === 0 ||
          prev[prev.length - 1].content !== systemMessage.content
        ) {
          return [...prev, systemMessage];
        }
        return prev;
      });

      scrollToBottom();
      setIsTyping(false);
    }
  }, [messageResponse.response]);

  useEffect(() => {
    if (isOpen) {
      history({
        page: pageInfo.page,
        page_size: pageInfo.pageSize,
      });
    }
  }, [isOpen, pageInfo.page]);

  useEffect(() => {
    if (historyReponse.response) {
      console.log('>>>>>>>>>>>historyResponse', historyReponse.response);
      setLoading(true);

      const history: ChatMessage[] = [];

      historyReponse.response.results.map(
        (historyItem: ICopilotChatHistoryReponse) => {
          const userMessage: ChatMessage = {
            type: 'user',
            content: historyItem.question,
            timestamp: new Date(historyItem.created),
          };

          const systemMessage: ChatMessage = {
            type: 'system',
            content: historyItem.answer,
            timestamp: new Date(historyItem.created),
          };
          history.push(systemMessage);
          history.push(userMessage);
        }
      );
      // Using reverse to display message descending based on time
      setChatHistory((prev) => [...history.reverse(), ...prev]);
      setLoading(false);
      setIsTyping(false);
      setPageInfo({
        ...pageInfo,
        next: historyReponse.response.next ? true : false,
      });
    }
  }, [historyReponse.response?.results]);

  useEffect(() => {
    if (pageInfo.page == 1) {
      scrollToBottom();
    }
  }, [chatHistory]);

  const handleScroll = () => {
    const container = chatContainerRef.current;
    if (container && !loading && hasMore) {
      if (container.scrollTop === 0 && pageInfo.next) {
        setPageInfo({ ...pageInfo, page: pageInfo.page + 1 });
      }
    }
  };

  const formatDate = (date: Date) => {
    return moment(date).format('D MMM YYYY');
  };

  const handlePromptSelect = (prompt: string) => {
    setInputValue(prompt);
  };

  const handlePromptSubmit = () => {
    if (!inputValue.trim() || isTyping === true) return;

    // Add user message to chat
    const userMessage: ChatMessage = {
      type: 'user',
      content: inputValue,
      timestamp: new Date(),
    };
    setChatHistory((prev) => [...prev, userMessage]);

    setIsTyping(true);
    setInputValue('');
    scrollToBottom();

    // Check if the inputValue is present in the presetPrompts
    const presetPrompt = presetPrompts?.find((p) => p.prompt === inputValue);
    if (presetPrompt) {
      // Send the id if the prompt is present
      debouncedSendMessage({ prompt: inputValue, id: presetPrompt.id });
    } else {
      // Send the prompt if it is not present in the presetPrompts
      debouncedSendMessage({ prompt: inputValue });
    }
  };

  const onTypingComplete = () => {
    setChatHistory((prev) =>
      prev.map((message, i) => {
        if (i === prev.length - 1) {
          const { typewriter, ...rest } = message;
          return rest;
        }
        return message;
      })
    );

    scrollToBottom();
  };

  const copilotButtonStyle = {
    position: 'fixed' as const,
    right: '24px',
    bottom: '24px',
    zIndex: 1000,
  };

  const chatContainerStyle = {
    height: '400px',
    overflowY: 'auto' as const,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '16px',
  };

  const messageStyle = {
    maxWidth: '80%',
    padding: '12px 16px',
    borderRadius: '8px',
    marginBottom: '8px',
  };

  const userMessageStyle = {
    ...messageStyle,
    backgroundColor: '#1890ff',
    color: 'white',
    alignSelf: 'flex-end',
  };

  const systemMessageStyle = {
    ...messageStyle,
    backgroundColor: '#f0f2f5',
    alignSelf: 'flex-start',
  };

  const inputContainerStyle = {
    display: 'flex',
    gap: '8px',
    padding: '16px',
    borderTop: '1px solid #f0f0f0',
  };

  const dateStyle = {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.45)',
    marginBottom: '4px',
  };

  return (
    <>
      {/* Co-pilot Button */}
      <div style={copilotButtonStyle}>
        <Button
          type="primary"
          shape="circle"
          icon={<RobotOutlined />}
          size="large"
          onClick={() => setIsOpen(true)}
          style={{
            width: '64px',
            height: '64px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          }}
        />
      </div>

      {/* Co-pilot Modal */}

      <Modal
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={600}
        closeIcon={<CloseOutlined />}
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <RobotOutlined />
            <span>Mo</span>
          </div>
        }
        bodyStyle={{ padding: 0 }}
      >
        {/* Chat History */}
        <div
          style={chatContainerStyle}
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          {loading && (
            <div style={{ textAlign: 'center', padding: '8px' }}>
              <Spin size="small" />
            </div>
          )}
          {chatHistory.map((message, index) => (
            <div
              key={index}
              style={
                message.type === 'user' ? userMessageStyle : systemMessageStyle
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginBottom: '4px',
                }}
              >
                {message.type === 'system' ? (
                  <Avatar
                    icon={<RobotOutlined />}
                    style={{ backgroundColor: '#1890ff' }}
                  />
                ) : (
                  <Avatar
                    icon={<UserOutlined />}
                    style={{ backgroundColor: '#87d068' }}
                  />
                )}
                <Text
                  strong
                  style={{
                    color:
                      message.type === 'user' ? 'white' : 'rgba(0, 0, 0, 0.85)',
                  }}
                >
                  {message.type === 'user' ? 'You' : 'Mo'}
                </Text>
                <Text
                  style={{
                    ...dateStyle,
                    color:
                      message.type === 'user'
                        ? 'rgba(255, 255, 255, 0.85)'
                        : undefined,
                  }}
                >
                  {formatDate(message.timestamp)}
                </Text>
              </div>
              {index === chatHistory.length - 1 &&
              message.typewriter === true &&
              message.type === 'system' ? (
                <Typewriter
                  text={message.content}
                  onComplete={onTypingComplete}
                />
              ) : (
                message.content
              )}
            </div>
          ))}
          {messageResponse.loading && (
            <div style={systemMessageStyle}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <Avatar
                  icon={<RobotOutlined />}
                  style={{ backgroundColor: '#1890ff' }}
                />
                <Text>Mo is typing...</Text>
              </div>
            </div>
          )}
          <div ref={chatEndRef} />
        </div>

        {/* Input Area */}
        <div style={inputContainerStyle}>
          <AutoComplete
            value={inputValue}
            onChange={setInputValue}
            onSelect={handlePromptSelect}
            options={presetPrompts?.map((p) => ({ value: p.prompt })) || []}
            style={{ flex: 1 }}
          >
            <Input
              placeholder="Type your question or select a suggestion..."
              disabled={isTyping === true ? true : false}
              onPressEnter={handlePromptSubmit}
              suffix={
                <Button
                  type="link"
                  icon={<SendOutlined />}
                  disabled={isTyping === true ? true : false}
                  onClick={handlePromptSubmit}
                />
              }
            />
          </AutoComplete>
        </div>
      </Modal>
    </>
  );
};

export default CoPilot;
