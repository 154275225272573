import React, { useEffect, useState, useMemo } from 'react';
import './category.styles.less';
import { connect } from 'react-redux';
import { Button, Pagination, Table, Space, Layout, Typography } from 'antd';
import moment from 'antd/node_modules/moment';
import { ColumnsType } from 'antd/lib/table';
import useAPI from '^/api-services/useApi';
import ActivityApi from '^/api-services/activities/activities.service';
import {
  ICategory,
  ICategoryListRequest,
  ICategoryListResponse,
} from '^/api-services/activities/types';
import { PAGE_SIZE, DATE_RANGE_FORMAT } from '^/common/constants';
import { CATEGORY_LABELS } from '^/common/labels-english';
import { StoreState } from '^/store/types';
import { Container, ContentBox } from '@dabapps/roe';
import { CategoryModal } from '^/app/pages/category/category-modal';

const { Title } = Typography;
const { Header } = Layout;
interface StateProps {
  currentSchool: string | null;
}

type Props = StateProps;

const CategoryList: React.FC<Props> = (props: Props) => {
  const { currentSchool } = props;
  const {
    callAPI: fireGetCategoryListApi,
    ...getCategoryListResponse
  } = useAPI<ICategoryListRequest, ICategoryListResponse>(
    ActivityApi.fetchCategoryPageData
  );
  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState('');
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (currentSchool || refresh) {
      fireGetCategoryListApi({
        page: pageInfo.page,
        page_size: pageInfo.pageSize,
      });
      setRefresh(false);
    }
  }, [pageInfo, refresh]);

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  const { button } = CATEGORY_LABELS;
  const action = (category: ICategory) => {
    return (
      <Space>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            setCategorySelected(category.id as string);
            setModalOpen(true);
          }}
          className="pill"
        >
          {button.viewOrEdit}
        </Button>
      </Space>
    );
  };

  const columnLabel = CATEGORY_LABELS.column;
  const getColumns = useMemo(() => {
    const columns: ColumnsType<ICategory> = [
      {
        title: columnLabel.category,
        dataIndex: 'category',
      },
      // {
      //   title: columnLabel.location,
      //   dataIndex: 'location',
      // },
      {
        title: columnLabel.created,
        dataIndex: 'created',
        render: (value: string) => moment(value).format(DATE_RANGE_FORMAT),
      },
      {
        title: columnLabel.action,
        dataIndex: 'action',
        render: (_: undefined, row: ICategory) => action(row),
      },
    ];
    return columns;
  }, []);

  return (
    <Layout className="category-management-container">
      <Header className="title-container">
        <Title level={3}>{CATEGORY_LABELS.rootTableTitle}</Title>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            setCategorySelected('');
            setModalOpen(true);
          }}
          className="pill"
        >
          {button.addCategory}
        </Button>
      </Header>
      <Container>
        <ContentBox>
          <Pagination
            current={pageInfo.page}
            total={getCategoryListResponse.response?.count}
            pageSize={pageInfo.pageSize}
            onChange={changePage}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            disabled={getCategoryListResponse.loading}
          />
          <Table
            rowKey="id"
            columns={getColumns}
            dataSource={getCategoryListResponse.response?.results || []}
            pagination={false}
            loading={getCategoryListResponse.loading}
          />
        </ContentBox>
      </Container>
      {isModalOpen && (
        <CategoryModal
          id={categorySelected}
          onClose={(refresh?: boolean) => {
            setModalOpen(false);
            if (refresh) {
              setRefresh(true);
            }
          }}
        />
      )}
    </Layout>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    currentSchool: state.currentSchool,
  };
}

export default connect(mapStateToProps)(CategoryList);
