import { Column, Container, Row } from '@dabapps/roe';
import * as React from 'react';
import { ILatestAssessment } from '^/api-services/users/types';

import { ActivityPageActivitiesSummary } from '^/app/authenticated/activities/activity-page/types';
import TimeChange, {
  Props as TimeChangeProps,
} from '^/app/authenticated/activities/overview/stats/time-change';
import { ACTIVITY_NAMES } from '^/common/constants';
import {
  DOTFORMAT,
  MONTHYEARFORMAT,
  formatDuration,
} from '^/common/utils/durations';
import { FEELING_NAMES, LOCATION_NAMES } from '../constants';
import moment from 'antd/node_modules/moment';
import { Select } from 'antd';

const ACTIVITY_BADGE = 'Activity Badge';
const REFLECTION_BADGE = 'Reflection Badge';
const SOCIAL_BADGE = 'Social Connectivity Badge';
interface Props {
  lastAssessment?: ILatestAssessment;
  activitiesSummary: ActivityPageActivitiesSummary;
  hasSucceededUserDetail: boolean;
  activityType: string;
  recordType: string;
  setActivityType: (val: string) => void;
  setRecordType: (val: string) => void;
}

interface SummaryDataProps {
  title: string;
  data: string;
  changeOptions?: TimeChangeProps;
  extra_text?: string;
  badgeIcon?: string;
}

function SummaryData({
  title,
  data,
  changeOptions,
  extra_text,
  badgeIcon,
}: SummaryDataProps) {
  return (
    <div className="margin-bottom-large">
      {/* <h6></h6> */}
      <div className="summary-data">
        <p style={{ margin: '0px' }}>
          <b>{title}:</b>&nbsp;{data}
        </p>
        {badgeIcon && (
          <img
            className="badge-icon"
            src={`/static/${badgeIcon.toLowerCase()}.png`}
            alt={badgeIcon}
          />
        )}
      </div>
      {extra_text && <p className="summary-data">{extra_text}</p>}
      {changeOptions && <TimeChange {...changeOptions} />}
    </div>
  );
}

function ConvertTagsTostring(tags: number, comments: number) {
  const tags_string = tags === 1 ? `${tags} tag` : `${tags} tags`;
  const comments_string =
    comments === 1 ? `${comments} like` : `${comments} likes`;

  return `${tags_string} and ${comments_string}`;
}

export default function SummaryBanner({
  lastAssessment,
  activitiesSummary,
  hasSucceededUserDetail,
  activityType,
  recordType,
  setActivityType,
  setRecordType,
}: Props) {
  return (
    <div className="activity-page-banner">
      <Container>
        <Row>
          <Column sm={12} md={6} className="mb-3">
            <div className="last-assessment">
              <h6>ACTIVITY DATA</h6>
              <Row>
                <Column sm={12} md={6}>
                  <Select
                    value={activityType}
                    onChange={(e) => setActivityType(e)}
                    className="select-filter-container margin-bottom-small"
                  >
                    <Select.Option value={'WEEK'} key={'WEEK'}>
                      {'Week'}
                    </Select.Option>
                    <Select.Option value={'MONTH'} key={'MONTH'}>
                      {'Month'}
                    </Select.Option>
                  </Select>
                </Column>
              </Row>
              <Row>
                <Column sm={12} md={6}>
                  <div className="activities-data">
                    <h6>
                      {activityType === 'MONTH' ? 'This Month' : 'This Week'}
                    </h6>
                    <hr></hr>
                    {activitiesSummary.current_week && (
                      <>
                        <SummaryData
                          title="Time"
                          data={formatDuration(
                            activitiesSummary.current_week
                              ?.activities_by_date_time
                          )}
                        />
                        <SummaryData
                          title="No of activities"
                          data={`${activitiesSummary.current_week?.no_of_activity_sessions}`}
                        />
                        <SummaryData
                          title="Most frequent activity"
                          data={`${
                            activitiesSummary.current_week
                              ?.most_frequent_activity_by_date
                              ? ACTIVITY_NAMES[
                                  activitiesSummary.current_week
                                    ?.most_frequent_activity_by_date
                                ]
                              : 'None'
                          }`}
                        />
                        <SummaryData
                          title="Most frequent emotion"
                          data={`${
                            activitiesSummary.current_week
                              ?.most_frequent_emotion
                              ? FEELING_NAMES[
                                  activitiesSummary.current_week
                                    ?.most_frequent_emotion
                                ]
                              : 'None'
                          }`}
                        />
                        <SummaryData
                          title="Most frequent setting"
                          data={`${
                            activitiesSummary.current_week
                              ?.most_frequent_setting
                              ? LOCATION_NAMES[
                                  activitiesSummary.current_week
                                    ?.most_frequent_setting
                                ]
                              : 'None'
                          }`}
                        />
                      </>
                    )}
                  </div>
                </Column>
                <Column sm={12} md={6}>
                  <div className="activities-data">
                    <h6>
                      {activityType === 'MONTH' ? 'Last Month' : 'Last Week'}
                    </h6>
                    <hr></hr>
                    {activitiesSummary.last_week && (
                      <>
                        <SummaryData
                          title="Time"
                          data={formatDuration(
                            activitiesSummary.last_week?.activities_by_date_time
                          )}
                        />
                        <SummaryData
                          title="No of activities"
                          data={`${activitiesSummary.last_week?.no_of_activity_sessions}`}
                        />
                        <SummaryData
                          title="Most frequent activity"
                          data={`${
                            activitiesSummary.last_week
                              ?.most_frequent_activity_by_date
                              ? ACTIVITY_NAMES[
                                  activitiesSummary.last_week
                                    ?.most_frequent_activity_by_date
                                ]
                              : 'None'
                          }`}
                        />
                        <SummaryData
                          title="Most frequent emotion"
                          data={`${
                            activitiesSummary.last_week?.most_frequent_emotion
                              ? FEELING_NAMES[
                                  activitiesSummary.last_week
                                    ?.most_frequent_emotion
                                ]
                              : 'None'
                          }`}
                        />
                        <SummaryData
                          title="Most frequent setting"
                          data={`${
                            activitiesSummary.last_week?.most_frequent_setting
                              ? LOCATION_NAMES[
                                  activitiesSummary.last_week
                                    ?.most_frequent_setting
                                ]
                              : 'None'
                          }`}
                        />
                      </>
                    )}
                  </div>
                </Column>
              </Row>
              <Row>
                <Column sm={12} md={12}>
                  <SummaryData
                    title="Total activity time"
                    data={
                      activitiesSummary.current_week
                        ? formatDuration(
                            activitiesSummary.current_week?.all_activities_time
                          )
                        : formatDuration(0)
                    }
                  />
                  <SummaryData
                    title="Total no of activities"
                    data={`${activitiesSummary.current_week?.all_activites_count}`}
                  />
                </Column>
              </Row>
            </div>
          </Column>
          <Column sm={12} md={4}>
            <div className="activities-summary">
              <h6>RECORDS</h6>
              <Row>
                <Column sm={12} md={12}>
                  <Select
                    value={recordType}
                    onChange={(e) => setRecordType(e)}
                    className="select-filter-container margin-bottom-small"
                  >
                    <Select.Option value={'WEEK'} key={'WEEK'}>
                      {'Week'}
                    </Select.Option>
                    <Select.Option value={'MONTH'} key={'MONTH'}>
                      {'Month'}
                    </Select.Option>
                  </Select>
                </Column>
              </Row>
              <Row>
                <Column sm={12} md={12}>
                  <SummaryData
                    title={`Most active ${recordType.toLocaleLowerCase()} (minutes)`}
                    data={`${
                      activitiesSummary.records?.best_minutes?.count
                        ? formatDuration(
                            activitiesSummary.records?.best_minutes?.count
                          )
                        : formatDuration(0)
                    }`}
                    extra_text={
                      recordType == 'WEEK'
                        ? `${moment(
                            activitiesSummary.records?.best_minutes?.week
                          )
                            .startOf('week')
                            .format(DOTFORMAT)} - ${moment(
                            activitiesSummary.records?.best_minutes?.week
                          )
                            .endOf('week')
                            .format(DOTFORMAT)}`
                        : `${moment(
                            activitiesSummary.records?.best_minutes?.month
                          )
                            .startOf('month')
                            .format(MONTHYEARFORMAT)}`
                    }
                  />
                </Column>
                <Column sm={12} md={12}>
                  <SummaryData
                    title={`Most active ${recordType.toLocaleLowerCase()} (no of activities)`}
                    data={`${activitiesSummary.records?.best_activities?.count}`}
                    extra_text={
                      recordType == 'WEEK'
                        ? `${moment(
                            activitiesSummary.records?.best_activities?.week
                          )
                            .startOf('week')
                            .format(DOTFORMAT)} - ${moment(
                            activitiesSummary.records?.best_activities?.week
                          )
                            .endOf('week')
                            .format(DOTFORMAT)}`
                        : `${moment(
                            activitiesSummary.records?.best_activities?.month
                          )
                            .startOf('month')
                            .format(MONTHYEARFORMAT)}`
                    }
                  />
                </Column>
                <Column sm={12} md={12}>
                  <SummaryData
                    title="Best streak"
                    data={`${
                      activitiesSummary.records?.streaks?.streak || '0'
                    }`}
                    extra_text={`${moment(
                      activitiesSummary.records?.streaks?.start_date
                    ).format(DOTFORMAT)} - ${moment(
                      activitiesSummary.records?.streaks?.end_date
                    ).format(DOTFORMAT)}`}
                  />
                </Column>
                <Column sm={12} md={12}>
                  <SummaryData
                    title={`Most social ${recordType.toLocaleLowerCase()}`}
                    data={ConvertTagsTostring(
                      activitiesSummary.records?.social?.tags || 0,
                      activitiesSummary.records?.social?.comments || 0
                    )}
                    extra_text={
                      recordType === 'WEEK'
                        ? `${moment(activitiesSummary.records?.social?.weekly)
                            .startOf('week')
                            .format(DOTFORMAT)} - ${moment(
                            activitiesSummary.records?.social?.weekly
                          )
                            .endOf('week')
                            .format(DOTFORMAT)}`
                        : `${moment(activitiesSummary.records?.social?.monthly)
                            .startOf('month')
                            .format(MONTHYEARFORMAT)}`
                    }
                  />
                </Column>
              </Row>
            </div>
          </Column>
          <Column sm={12} md={2}>
            <div className="activities-summary">
              <h6>POINTS</h6>
              <span className="margin-bottom-large">
                &nbsp;<br></br>
              </span>
              <span className="margin-bottom-large">&nbsp;</span>

              {activitiesSummary.wallet && activitiesSummary.badges && (
                <>
                  <SummaryData
                    title="Level"
                    data={`${activitiesSummary.wallet?.level?.level}`}
                  />
                  <SummaryData
                    title="Active points"
                    data={`${activitiesSummary.wallet?.activity_points}`}
                    badgeIcon={activitiesSummary.badges[ACTIVITY_BADGE]}
                  />
                  <SummaryData
                    title="Reflection points"
                    data={`${activitiesSummary.wallet?.reflection_points}`}
                    badgeIcon={activitiesSummary.badges[REFLECTION_BADGE]}
                  />
                  <SummaryData
                    title="Social points"
                    data={`${activitiesSummary.wallet?.social_points}`}
                    badgeIcon={activitiesSummary.badges[SOCIAL_BADGE]}
                  />
                </>
              )}
            </div>
          </Column>

          {/* <Column sm={12} md={4}>
            <div className="activities-summary">
              <Row>
                <Column sm={12} md={6}>
                  <SummaryData
                    title="NO OF ACTIVITY SESSIONS"
                    data={`${activitiesSummary.no_of_activity_sessions} Activities`}
                  />
                  <SummaryData
                    title="NO OF DIFFERENT ACTIVITY SESSIONS"
                    data={`${activitiesSummary.no_of_different_sessions} Activities`}
                  />
                  <SummaryData
                    title="MOST FREQUENT ACTIVITY"
                    data={`${
                      activitiesSummary.most_frequent_activity_by_date
                        ? ACTIVITY_NAMES[
                            activitiesSummary.most_frequent_activity_by_date
                          ]
                        : 'None'
                    }`}
                  />
                </Column>
                <Column sm={12} md={6}>
                  <SummaryData
                    title="MOST ACTIVE DAY"
                    data={`${activitiesSummary.most_active_day}`}
                  />
                  <SummaryData
                    title="AVERAGE MINUTES"
                    data={`${
                      activitiesSummary.average_minutes
                        ? formatDuration(activitiesSummary.average_minutes)
                        : 'None'
                    }`}
                  />
                  <SummaryData
                    title="MOST FREQUENT CATEGORY"
                    data={`${
                      activitiesSummary.most_frequent_category_by_date
                        ? activitiesSummary.most_frequent_category_by_date
                        : 'None'
                    }`}
                  />
                </Column>
              </Row>
              <hr></hr>
              <Row>
                <Column sm={12} md={6}>
                  <SummaryData
                    title="MOST FREQUENT EMOTION"
                    data={`${
                      activitiesSummary.most_frequent_emotion
                        ? FEELING_NAMES[activitiesSummary.most_frequent_emotion]
                        : 'None'
                    }`}
                  />
                  <SummaryData
                    title="MOST FREQUENT SETTING"
                    data={`${
                      activitiesSummary.most_frequent_setting
                        ? LOCATION_NAMES[
                            activitiesSummary.most_frequent_setting
                          ]
                        : 'None'
                    }`}
                  />
                </Column>
                <Column sm={12} md={6}>
                  <SummaryData
                    title="ACTIVITIES ALL TIME"
                    data={`${activitiesSummary.all_activites_count} Activities`}
                  />
                  <SummaryData
                    title="TIME ALL TIME"
                    data={formatDuration(activitiesSummary.all_activities_time)}
                  />
                </Column>
              </Row>
              <hr></hr>
              <Row>
                <Column sm={12} md={6}>
                  <SummaryData
                    title="MIPOINTS"
                    data={`${activitiesSummary.student_points} mipoints`}
                  />
                  <SummaryData
                    title="BEST STREAK"
                    data={`${
                      activitiesSummary.streaks?.streak
                        ? `${activitiesSummary.streaks?.streak} (${moment(
                            activitiesSummary.streaks?.start_date
                          ).format('DD.MM.YYYY')} - ${moment(
                            activitiesSummary.streaks?.end_date
                          ).format('DD.MM.YYYY')})`
                        : 'None'
                    }`}
                  />
                </Column>
                <Column sm={12} md={6}>
                  <SummaryData
                    title="CURRENT TARGET"
                    data={`${
                      activitiesSummary.target?.current
                        ? activitiesSummary.target?.current?.target
                        : 'None'
                    }`}
                  />
                  <SummaryData
                    title="PREVIOUS TARGET"
                    data={`${
                      activitiesSummary.target?.previous
                        ? activitiesSummary.target?.previous?.target
                        : 'None'
                    }`}
                  />
                </Column>
              </Row>
            </div>
          </Column> */}
        </Row>
      </Container>
    </div>
  );
}
